@keyframes animate {
  0% {
    background: #4189dd;
  }
  50% {
    background: #87aeda;
  }
  100% {
    background: #4189dd;
  }
}
/* @keyframes hightlightInputMail {
  0% {
   transform: scale(1,1); 
    -webkit-transform: scale(1,1);
    -moz-transform: scale(1,1);
  }
  40% {
    transform: scale(1.3,1.3);    
    -webkit-transform: scale(1.3,1.3);
    -moz-transform: scale(1.3,1.3);
  }
  55% {
    transform: scale(1,1);    
    -webkit-transform: scale(1,1);
    -moz-transform: scale(1,1);
  }  
} */
.button:active {
  animation: animate 0.5s;
}
/* .highlightInputMail {
  animation-name: highlightInputMail;
  animation-duration:2s;
  -webkit-animation-name: hightlightInputMail;
  -webkit-animation-duration: 2s;
  -moz-animation-name: hightlightInputMail;
  -moz-animation-duration: 2s;
} */
/* .highlightInputMail:focus-visible {
  -webkit-animation-name: hightlightInputMail;
  -webkit-animation-duration: 2s;
  -moz-animation-name: hightlightInputMail;
  -moz-animation-duration: 2s;
} */
::-webkit-input-placeholder {
  color: #4189dd;
  opacity: 0.3;
}
.changeBackground ::-webkit-input-placeholder {
  color: #ffffff;
  opacity: 1;
}
.changeBackground input {
  color: white !important;
}
#form {
  display: flex;
}
.inputMailLeft {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
}
.inputMailRight{
  display: flex;
}
.containerForm {
  background: white;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 250px;
  height: 23px ; */
  
  width: 275px;
  height: 30px;

  border-radius: 6px ;
}
.container{
  width:99.5%;
  display: flex;
  align-items: center;
}
.mailImage {
  width: 85% ;
  /* width:100% */
}
.changeBackground{
  background: transparent;
  margin-top: 14%;
  width:200px;
  height:21px;
  /* margin-top:12% */
}
.mailImageDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2%;
}

.button {
  /* width: 181px; */
  /* height: 50px; */
  /* border-radius: 14px; */
  /* margin-right:0.5px; */
  color: #ffffff;
  background: #4189dd;
  border: none;
  font-weight: 500;
  width: 120px ;
  height: 27px;
  border-radius: 5px;
  font-size: 10px ;
}
.buttonFooter{
  width: 70px ;
  height: 18px ;
  border-radius: 5px ;
  font-size: 5px ;
  margin-right:1px ;
}
.inputMailLeft input {
  font-weight: 400;
  /* font-size: 18px; */
  line-height: 27px;
  border: none;
  background-color: transparent;
  outline: none;
  color: black;
  font-size: 9px ;
  -webkit-user-select: text;
  user-select: text;
}
.changeBackground input{
  font-size: 7px ;
}
.mailImageFooter{
  width:60%;
  margin-left:3%;
  /* justify-content: center; */
}
.mailImageDivFooter{
  justify-content: center;
   display: flex;
    align-items: center;
}
@media (min-width: 481px)  {
  .containerForm {
    width: 300px;
    height: 34px;
    border-radius: 7px;
  }
  .changeBackground{
    margin-top: 12%;
  }
 
  .inputMailLeft input {
    font-size: 10px;
  }
  .button {
    width: 120px;
    height: 29px;
    border-radius: 7px;
    font-size: 10px;
    margin-right: 1px;
  }
  .buttonFooter{
    margin-right: 1px;
    border-radius:6px;
    height: 29px;
  }
  .mailImageDiv{
    margin-left:5%;
  }
  .mailImageFooter{
    width:65%;
  }
} 
@media (min-width:768px ) {
  .mailImageDivFooter{
    margin-left: 5%;
  }
}
@media (min-width:1023px) {
  .containerForm {
    width: 527px;
    height: 55px;
     border-radius: 15px;
  }
  .mailImage {
    width:100%
  }
  .changeBackground{

    margin-top:8%
  }
  .inputDiv{
    margin-left: 3%;
  }
  .button {
    width: 181px;
    height: 50px;
    border-radius: 14px;
    margin-right:0.5px;
    font-size: 16px;
  }
  /* .buttonFooter{
    width: 80px ;
    height: 19px ;
    border-radius: 5px ;
    font-size: 8px ;
    margin-right:0.5px ;
  } */
  .inputMailLeft input {
    font-size: 16px;
  }
  .mailImageFooter{
    width:100%;
  }
  .mailImageDivFooter{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 5%;
  }
}
@media (min-width:1200px) {
  .inputMailLeft input {
    font-size: 18px;
  }
 
}
