.container {
  display: flex;
  /* justify-content: center; */
  height: 80px;
  align-items: center;
  
}
.toggleButton {

  width: 220px;
  height: 35px;
  background-color: rgba(65, 137, 221, 0.1);
  border-radius: 8px;
}
.toggleButtons {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  border: none;
  color: #01435a;
  border-radius: 8px;
  width: 110px;
  height: 35px;
  font-size: 15px;
}
.toggleLeft {
  color: #ffffff;
  background: #4189dd;
  border-radius: 8px;
  animation: fadeOut 1s;
  font-weight: 600;
}

.toggleRight {
  color: #ffffff;
  background: #4189dd;
  border-radius: 9px;
  animation: fadeOut 1s;
  font-weight: 600;
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width:1024px) {
  .container{
      align-items: flex-end;
  height: 120px;
  border-radius: 15px;
  }
  .toggleButton{
      width: 335px;
  height: 60px;
  border-radius: 15px;
  }
  .toggleButtons{
 width: 167px;
  height: 60px;
   font-size: 18px;
   border-radius: 15px;
  }
}