/* .container {
  width: 180px;
  height: auto;
}
@media ((min-width: 320px) and (max-width: 480px)) {
  .container * {
    width: 65px;
    margin-left: 32px;
  }
  
}
@media ((min-width: 481px) and (max-width: 767px)) {
  .container {
    width: 80px;
  }
  
} */
.container{
  width: 64px;
}
@media (min-width: 481px) {
  .container{
    width: 80px;
  }
}
@media (min-width: 768px) {
  .container{
    width: 180px;
  }
}