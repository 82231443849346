.thankyoucontent
{
  
    display:flex;
    margin:auto;
    margin-top: 10%;
    padding: 60px;
    margin-left: 22%;
    /* justify-content: center;
    align-items: center; */
    /* margin:"auto"; */
  
}
.thankimg{
    width:200px;
    height:200px;
    margin:auto;
   
}
@media(max-width:820px)
{
    .thankyoucontent{
        margin-left: 0px;
    }
    .thankimg{
        width:100px;
        height:100px;
    } 
}
@media screen and (min-width:800px) and (max-width:980px)and (min-height:1300px) and (max-height:1522px)
{
    .thankyoucontent
    {
        margin-left: 3%;
    }
}