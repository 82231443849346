.dropdown {
  display: flex;
  background-color: white;
  max-height: 30px !important;
}
.dropdown ul {
  position: absolute;
  height: 200px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  width: 69px;
  right: -31px;
  top: 28px;
  -webkit-overflow-scrolling: touch;
  background-color: white;
  color: black;
  padding: 0px;
  
}
.dropdown li {
  list-style-type: none;
}
.dropdown ul li:hover {
  background-color: #1eb0d9;
  color: rgb(248, 245, 242);
  position:relative;
  z-index:99;
  
}
@media(min-width:481px){
  .dropdown ul {
    width: 80px;
    top: 30px;
    right: -35px;
  }
}
@media(min-width:767px){
  .dropdown ul {
    width: 97px;
    top: 50px;
    right: -42px;
  }
}

@media (min-width: 1024px) {
  .dropdown ul  {
    width: 70px;
    top: 50px;
    right: -26px;
    height:400px;
    border:1px solid black;
    border-radius: 2px;
  }
  .dropdown ul li{
    font-size:20px;
    font-weight: lighter;
    line-height: 40px;
  }
}
