.legalpage{
    font:Poppins;
}
.headingterms{
    font-weight: 700;
    font-size: 45px !important;
}
@media(max-width:768px)
{
    .headingterms{
        
        font-size: 30px !important;
    }   
}
@media(max-width:420px)
{
    .headingterms{
        
        font-size: 25px !important;
    }   
}
/* @media screen and (min-width:950px) and (max-width:952px) and (min-height:1520px) and (max-height:1522px) {
    .legalpage{
        padding:82px !important;
    }
    .commonpara{
        margin-top: 7% !important;
    }
    .scanbtn{
          width:182px;
    }
  }
  */

  @media screen and (min-width:800px) and (max-width:980px)and (min-height:1300px) and (max-height:1522px)
{

    .legalpage
    {
        height:65vh;
        overflow:scroll;
    }
}