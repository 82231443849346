.headerContainer{
  background-color: #4189dd;
}
.headerWareeg{
  height:50px; 
  padding: 0 7%;
}
.earlyAccessButton {
  width: 130px;
  height: 30px;
  font-size: 11px;
  border-radius: 7px;
  background: #ffffff; 
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 28px;
  border: none;
  color: #4189dd;
   /* z-index:1; */
}
.ReactModal__Overlay--after-open{
background-color:rgb(0 0 0 / 59%) !important;
}
/* opacity: 1;
}

/* .earlyAccessSpan{
  z-index:-1;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 28px;
  border: none;
  color: #4189dd;
} */
.headerImage {
  width: 80px;
  height: 20px;
  align-items: center;
  cursor:pointer;
}
.scannerimg{
  width:29px;
  height:29px;
}
@keyframes animate {
  0% {
    opacity:0;
  }
  50% {
    opacity:1
  }
}
/* .earlyAccessButton:active {
  animation: animate 0.5s;
} */
.headerImageClicked{
  animation: animate 0.5s;
  }
  @media (min-width:768px){
    .headerWareeg{
      height:92px;
    }
    .earlyAccessButton {
      width: 181px;
height: 55px;
      font-style: normal;
      font-family: "Poppins";
      font-weight: 500;
      font-size: 16px;
      border-radius: 15px;
  }
  .headerImage {
    width: 151px;
    height: 55px;
   
  }

 
}
@media(max-width:768px)
{
  .scannerimg{
    width:20px;
    height:20px;
  }
  
}
@media(max-width:430px)
{
  .earlyAccessButton
  {
    font-size:10px !important;
    width:147px;
    padding:10px;
  }
}
@media(max-width:400px)
{
  .earlyAccessButton
  {
    padding:0px;
  }
}