* {
    margin: 0;
    padding: 0;
    text-indent: 0;
}

h1 {
    color: #374151;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 24pt;
}

p {
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
    margin: 0pt;
    line-height: 3rem;
    margin-bottom: 0.5rem !important;
}

h2 {
    color: #374151;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

li {
    display: block;
}

#tl1 {
    padding-left: 0pt;
    counter-reset: c1 1;
}

#tl1> li>*:first-child:before {
    counter-increment: c1;
    content: counter(c1, decimal)".";
    color: #4189DD;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600 !important;
    text-decoration: none;
    font-size: 12pt;
}

#tl1> li:first-child>*:first-child:before {
    counter-increment: c1 0;
}

#tl2 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl2> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color:#6B6B6B;
    font-family: "Poppin", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl2> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl3 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl3> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color:#6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl3> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl4 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl4> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color:#6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl4> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl5 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl5> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl5> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl6 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl6> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl6> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl7 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl7> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl7> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl8 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl8> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl8> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl9 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl9> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color:#6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl9> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl10 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl10> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl10> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl11 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl11> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color:#6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl11> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl12 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl12> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color:#6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl12> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl13 {
    padding-left: 0pt;
    counter-reset: c3 1;
}

#tl13> li>*:first-child:before {
    counter-increment: c3;
    content: counter(c1, decimal)"."counter(c2, decimal)"."counter(c3, decimal)". ";
    color:#6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl13> li:first-child>*:first-child:before {
    counter-increment: c3 0;
}

#tl14 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl14> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl14> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl15 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl15> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl15> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl16 {
    padding-left: 0pt;
    counter-reset: c3 1;
}

#tl16> li>*:first-child:before {
    counter-increment: c3;
    content: counter(c1, decimal)"."counter(c2, decimal)"."counter(c3, decimal)". ";
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl16> li:first-child>*:first-child:before {
    counter-increment: c3 0;
}

#tl17 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl17> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color:#6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl17> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl18 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl18> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl18> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl19 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl19> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl19> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl20 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl20> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl20> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl21 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl21> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color:#6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl21> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl22 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl22> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl22> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl23 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl23> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color:#6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl23> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl24 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl24> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color:#6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl24> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl25 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl25> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl25> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}

#tl26 {
    padding-left: 0pt;
    counter-reset: c2 1;
}

#tl26> li>*:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal)"."counter(c2, decimal)". ";
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
}

#tl26> li:first-child>*:first-child:before {
    counter-increment: c2 0;
}
@media(max-width:500px)
{
    #tl1> li>*:first-child:before,#tl2> li>*:first-child:before,#tl3> li>*:first-child:before,#tl4> li>*:first-child:before,#tl5> li>*:first-child:before,#tl6> li>*:first-child:before,#tl7> li>*:first-child:before,#tl8> li>*:first-child:before,#tl9> li>*:first-child:before,#tl10> li>*:first-child:before
    ,#tl11> li>*:first-child:before,#tl12> li>*:first-child:before,#tl13> li>*:first-child:before,#tl14> li>*:first-child:before,#tl15> li>*:first-child:before,#tl16> li>*:first-child:before,#tl17> li>*:first-child:before,#tl18> li>*:first-child:before,#tl19> li>*:first-child:before,#tl20> li>*:first-child:before  ,
    #tl21> li>*:first-child:before,#tl22> li>*:first-child:before,#tl23> li>*:first-child:before,#tl24> li>*:first-child:before,#tl25> li>*:first-child:before,#tl26> li>*:first-child:before
        {
            font-size:7pt;
        }


}