.experiencerContainer{
  margin-top:50px;
}
.eachContent{
  display:flex;
  flex-direction: row;
}
.paraHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #103662;
  text-align: left;
}

.paraContent {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #103662;
  text-align: left;
}
.browse{
  width:300%;
}
@media(min-width:320px){
  .browse{
    width:200%;
  }
}
@media(min-width:360px){
  .browse{
    width:150%;
  }
}

@media(min-width:481px){
  .browse{
    width:100%;
  }
}
@media(min-width:1024px){
  .browse{
    width:100%;
    margin-top:5px;
  }
  .eachContent{
    width: 90%;
  }
  .paraHeading{
    font-size: 20px;
line-height: 28px;
}
.paraContent{
  font-size: 16px;
line-height: 26px;
}
}
@media(min-width:1200px){
  .eachContent{
    width:80%;
  }
}
@media(min-width:1300px){
  .browse{
    width:108%;
    
  }
  .eachContent{
    width:70%;
    margin-bottom: 15px;
  }
 

}
/* .rightDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
}
.eachContent {
  display: flex;
}
.images {
  width: 10%;
}
.para {
  width: 65%;
}

.paraContent {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #103662;
}

.localButton {
  width: 160px;
  height: 50px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4189dd;
  background: #c6dcf5;
  border-radius: 15px;
  text-align: center;
  border: none;
}
.experiencerButton {
  visibility: hidden;
}
@media ((min-width: 320px) and (max-width: 767px)) {
  .rightDiv {
    height: 340px;
    justify-content: flex-start;
  }
  .para {
    width: 100%;
  }
  .paraHeading {
    font-size: 15px;
    line-height: 18px;
  }
  .paraContent {
    font-size: 13px;
    line-height: 20px;
  }
  .images {
    width: 11%;
  }
  .images img{
    width:20px;
  }
  .localButton {
    width: 140px;
    height: 35px;
    font-size: 12px;
    border-radius: 7px;
  }
}
@media ((min-width: 481px) and (max-width: 767px)) {
  .rightDiv {
   
    justify-content: center;
  }
}
@media ((min-width: 768px) and (max-width: 1024px)) {
  .para {
    width: 75%;
  }
  .images {
    width: 15%;
  }
} */
