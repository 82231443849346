.amountDiv{
  display: flex;
  justify-content: space-around;
  margin: 5% 10%;
  padding: 0px 5px;
}
.amountDiv img {
  width: 30px;
  /* margin: -30px 0 0 -16px; */
  margin: -106px 0 0 -16px;
}
.lineAlignment {
  display: flex;
}
.wareegAmount{
  display:flex;
  flex-direction: row;
}
.amountWareeg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5% 15%;
  background: #10a667;
  border: 1px solid rgba(65, 137, 221, 0.2);
  border-radius: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  background: #10a667;
  font-size: 20px;
  line-height: 40px;
  width: 130px;
}
.amountOthers {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5% 15%;
  color: #fc5858;
  border: 1px solid rgba(16, 54, 98, 0.2);
  border-radius: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  /* font-size: 50px; */
  /* line-height: 55px; */
  text-align: center;
  color: #fc5858;
  background: #fceaec;
  /* width: 270px; */
  font-size: 20px;
  line-height: 40px;
  width: 130px;
  flex-direction: row;
}
.othersAmount
{
  display:flex;
  flex-direction: row;
}
.p1,.p3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;

  text-align: center;
  color: #778fab;
  margin-top: 5%;
  font-size: 8px;
  line-height: 15px;
}
.p2,.p4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
 
  line-height: 25px;
  text-align: center;
  color: #103662;
  font-size: 12px;
}
@media (min-width:768px) {
    .amountWareeg {
    font-size: 25px;
    width: 140px;
    height:60px;
  }
  .amountOthers {
    font-size: 25px;
    width: 200px;

  }
  .p1,.p3 {
    font-size: 11px;
  }
  .p2,.p4 {
    font-size: 19px;
  }
}
@media(max-width:952px)
{
  .wareegAmount{
    flex-direction: column;
    flex-flow: column-reverse;
  }
  .amountWareeg {
   /* // font-size: 25px; */
    width: 200px;
    margin-bottom: 3px;
  }
  .amountOthers {
      height:59px;
    margin-bottom: 1px;
  }
  .p1{
    margin-top: 0%;
    margin-bottom: 3px;
  }
  .p3{
    margin-top: 0%;
    margin-bottom: 3px;
    text-indent: -36px;
  }
  .p2 {
   line-height: 8px;
  
  }
  .p4 {
    line-height: 8px;
    text-indent: -24px;
   
   }
  .othersAmount
{
  
  flex-direction: column;
}
}
@media (min-width: 1200px) {
  .parastwo{
    width:160px;
  }
  .amountDiv {
    margin-top: 10%;
  }
  .amountDiv img {
    height: 52px;
    width: 52px;
    margin: -30px 0 0 -24px;
  }

.amountWareeg{
      
  width: 140px;
  height:60px;
}
.amountOthers{
  font-size:25px;
  width: 140px;
  height:60px;
}
.p1,.p3{
    font-size: 14px;
  line-height: 25px;
}
.p2,.p4{
   font-size: 18px;
}
}
@media (max-width: 500px) {
  .amountWareeg{
    width:120px;
    height:56px;
  }
  .wareegAmount{
    width:120px;
  }
  .amountOthers{
    
    width: 120px;
    height:56px;
   
  }
}
@media screen and (min-width:800px) and (max-width:980px)and (min-height:1300px) and (max-height:1522px){
  .p1,.p3{
    margin-top: 10px;
  }
  .p2,.p4{
    margin-top: 5px;
  }
}


