.container{
  display: flex;
  background: linear-gradient(180deg, rgba(236, 243, 252, 0) 0%, #ecf3fc 100%);
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 6%;
}
.calculator{
  width: 80%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.disclaimer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 14px;
  text-align: center;
  color: rgba(16, 54, 98, 0.5);
  flex-grow: 1;
}
.bottomDiv {
  display: flex;
  flex-direction: column;
  /* margin: 20px; */
  /* added */
  margin-bottom:20px;
}
/* @media (min-width:481px) {
  .calculator {
    width: 60%;
  }
} */
@media (min-width:481px) {
  .calculator {
    width: 60%;
  }
  .disclaimer{
    font-size: 9px;
  }
}
@media (min-width:768px) {
  .container {
    height: 450px;
    align-items: center;

  }
  
  .disclaimer {
    font-size: 12px;
    line-height: 18px;
  }
 
}
@media (min-width:1200px) {
  .disclaimer {
    font-size: 14px;
    line-height: 25px;
  }
  .bottomDiv {
    margin-bottom: 25px;
  }
}
@media (min-width:1300px) {
  .container {
    height: 548px;
    margin:auto;
  }
  .calculator{
    width:40%;
  }
  
  
}










