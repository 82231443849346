.input{
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: #103662;
  position: relative;
  font-size: 19px;
  /* line-height: 40px; */
  width:100%;
  /* added */
  margin-bottom:20px;
}
.select{
  background-image: url("../../assets/wareeg/downArrow.svg");
  background-repeat: no-repeat;
  display: inline-flex;
  justify-content: center;
  background-color: transparent;
  color: #1eb0d9;
  text-align: center;
  border-bottom: 2px solid #103662;
  width: 17%;
  background-size: 20% 20%;
  background-color: transparent;
  background-position: top 55% left 90%;
}


/* .input {
  font-size: 35px;
  line-height: 62px;
} */



@media (min-width: 481px){
  .input {
    font-size: 20px ;
    line-height: 30px;
    /* width: 70%; */
    /* margin-left: 15%; */
  
  }
}
@media (min-width: 768px)  {
  .input {
    font-size: 28px;
    line-height: 62px;
    width: 99%;
    margin-left: 1%;
  }
  .select {
    width: 18%;
    background-position: top 56% left 95%;
    /* background-size: 100% 100%; */
   
  }
}
@media((min-width:1024px)){
  .input{
    font-size: 35px;
    line-height: 62px;
    width:100%;
    margin-left: 0;
  }
  .select {
    width: 16%;
  }
}

@media (min-width: 1200px) {
  .input{
    width:100%; 
  }

.select {
  width: 15%;
  height: 40%;
  background-position: top 70% left 95%;

}
}