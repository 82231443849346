.container {
  display: flex;
  /* height: 297px; 151*/
  height:430px;
  background-color: #061a32;
}
.innerContainer {
  width: 100%;
  margin: 0 7%;
  display: flex;
  flex-direction: column;
  padding:60px 60px 60px 0;
}
.footerTop {
  width: 100%;
  display: flex;
  /* height: 188px; */
}
.footerTopLeft {
  display: flex;
  flex-grow: 1;
  align-self: center;
}

.footerTopRight {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-self: center;
  width: 70%;
}
.footerBottom {
  padding-top: 5px;
  /* height: 77px; */
  display: flex;

}
.footerBottomLeft {
  display: flex;
  /* justify-content: space-between; */
  flex-grow: 1;
  column-gap: 5%;
}
.footergetwareegbtn{
  width:170px !important;
}

.footerBottomRight {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  /* font-size: 16px; */
  font-size: 8px;
  line-height: 24px;
  color: #ffffff;
}

.wareegImage {
  /* width: 137.17px;
  height: 33.28px; */
  width: 60px;
  height: 17px;
  margin-bottom:20px;
}
.namespolicy{
  font-size:18px;
  
}
.namespolicylc{
  font-size:18px ;
  margin-left: 50px;
  
}
.support
{
  font-size:21px;
}

hr.one {
  height: 2px;
  background-color: rgba(255, 255, 255, 0.2);
  width:106%;
}
.subscriptionDetails {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  color: #ffffff;
  visibility: hidden;
  text-align: left;
  /* font-size: 14px; */
  font-size: 7px;
}
.subscriptionDetailsVisible {
  visibility: visible;
}
.image{
  width:50%;
}
.footerfirstrow{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
 }
 .footerfirstcol
 {
display:flex;
flex-direction: column;
padding:10px;
justify-content: space-between;
margin-left:15% ; 
font-size:2rem;
 }
 .footerfirstcolm
 {
  display:flex;
flex-direction: column;
padding:10px;
justify-content: space-between;
/* margin-left:15% ; */
font-size:2rem;
 }
 .footerthirdcolrow{
  font-size:2rem;
 }
 .footersecondsecondcol
 {
  font-size: 1.5rem;
 }
 
 .footersecondrow{
color:#fff;
display:flex;
justify-content: space-between;
flex-direction:"row";
font-size:2rem;
 }
 .footerthirdcol{
  margin-left:15% !important;
  font-size:2rem;
 }
 .footersecondfirstcol{
  font-size:1.5rem;
 }
 .scanbtn{
  font-size:17px;
 }
@media (min-width:481px){
  .subscriptionDetails{
    font-size: 10px;
  }
  .wareegImage{
    width: 83px;
    height: 27px;
  }
}
@media (min-width:1024px) {
  .container{
    height: 430px;
  }
  .footerTop {
    height: 188px;
  }
  .footerBottom {
    height: 77px;
  }
  .footerBottomRight{
    font-size: 16px;
  }
  .wareegImage {
    width: 137.17px;
    height: 33.28px;
  }
  .subscriptionDetails{
    font-size: 14px;
  }
  .image{
    width: 100%;
  }
  .footerBottomLeft{
    column-gap: 7%;
  }
}
@media(max-width:820px)
{
  hr.one {
    
    width:100%;
  }
  .wareegImage {
    /* width: 137.17px;
    height: 33.28px; */
    width: 100px;
    /* height: 17px; */
  }
  .footerBottomLeft a
  {
    width:40px;
    height:40px;
  }
  .scannerimg {
    width:20px !important;
   }
   .namespolicylc{
  
    margin-left: 0px;
    
  }
}
@media(max-width:500px)
{
  hr.one {
    margin-left: 10px;
  }
}
@media(min-width:821px) and (max-width:900px)
{
  .scanbtn{
    width:180px !important;
    padding:14px 20px !important;
  }
  .footerfirstcol{
    margin-left: 5%;
  }
}
@media(min-width:768px) and (max-width:820px)
{
  .scanbtn{
    width:163px !important;
    padding:14px 18px !important;
  }
}
@media (max-width:951px)
{
  .footerthirdcol{
    margin-left:6% !important;
   
   }
}
@media (max-width:481px){
  .container {
    height:300px;
  }
  .innerContainer {
    padding:20px 20px 20px 0;
  }
  .footerfirstcol
 {
font-size:1rem;
margin-left:0% ;
 }
 
 .footersecondrow{
  font-size:1rem;
margin-left:0%;
 }
 .footerthirdcol{
  font-size:1rem;
  margin-left:8% !important;
 }
 .footerthirdcolrow{
  font-size:1rem;
 }
 .footersecondfirstcol
 {
  font-size:1rem;
 }
 .footersecondsecondcol
 {
  font-size:1rem;
 }
 .wareegImage {
  /* width: 137.17px;
  height: 33.28px; */
  width: 100px;
  /* height: 17px; */
}
.footerBottomLeft a
{
  width:40px;
  height:40px;
}
}
@media( max-width:430px)
{
  .namespolicy{
    font-size:10px;
    margin-bottom: 0px;
    line-height: 10px !important;
  }
  .namespolicylc{
    font-size:10px;
    margin-bottom: 0px;
    line-height: 10px !important;
  
    margin-left: 0px;
    
  }
  .support
  {
    font-size:12px;
  } 
  .innerContainer {
    margin:0px 0px;
  }
  .foooterterms{
    margin-left: -17px;
  }
  .footergetwareegbtn{
    width:150px !important;
    padding:10px 7px;
  }
  .footerfirstcol{
  margin-top:-24px !important;
  }
  .scanbtn{
    font-size:12px;
    width:143px !important;
    padding:14px 8px !important;
  }
  .scannerimg {
   width:20px !important;
  }
}

/* .container {
  display: flex;
  height: 297px;
  background-color: #061a32;
}
.innerContainer {
  width: 100%;
  margin: 0 7%;
  display: flex;
  flex-direction: column;
}
.footerTop {
  width: 100%;
  display: flex;
  height: 188px;
}
.footerTopLeft {
  display: flex;
  flex-grow: 1;
  align-self: center;
}

.footerTopRight {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-self: center;
}
.footerBottom {
  padding-top: 5px;
  height: 77px;
  display: flex;
}
.footerBottomLeft {
  display: flex;
  flex-grow: 1;
  column-gap: 5%;
}

.footerBottomRight {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.wareegImage {
  width: 137.17px;
  height: 33.28px;
}

hr.one {
  height: 2px;
  background-color: rgba(255, 255, 255, 0.2);
}
.subscriptionDetails {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  color: #ffffff;
  visibility: hidden;
  text-align: left;
  font-size: 14px;
}
.subscriptionDetailsVisible {
  visibility: visible;
}
@media ((min-width: 320px) and (max-width: 767px)) {
  .container {
    height: 151px;
  }
  .image {
    width: 60%;
  }
  .wareegImage {
    width: 60px;
    height: 17px;
  }
  .footerBottomRight {
    font-size: 8px;
  }
  .footerTopRight {
    width: 70%;
  }
 
}

@media ((min-width: 768px) and (max-width: 1024px)) {
  .container {
    
    height:auto;
  }
  .image{
    width:80%;
  }
}
@media ((min-width: 320px) and (max-width: 1023px)) {
 .subscriptionDetails{
  font-size: 10px;
 }
} */
/* @media screen and (min-width:950px) and (max-width:952px) and (min-height:1520px) and (max-height:1522px) {
  .scanbtn{
    width:182px;
  }
  .image{
    width:25px;
  }
  .wareegImage{
    width:140px;
  }
} */

@media screen and (min-width:800px) and (max-width:980px)and (min-height:1300px) and (max-height:1522px)
{
  .image{
    width:25px;
  }
  .wareegImage{
    width:140px;
  }
  .scanbtn{
    width:182px;
  }
  .namespolicylc{
   
    margin-left: 0px;
    
  }
}