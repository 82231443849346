* {
    margin: 0;
    padding: 0;
    text-indent: 0;
}

.s1 {
    color: black;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 24pt;
}

p {
    color: #6B6B6B;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
    margin: 0pt;
}

h1 {
    color: #374151;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 12pt;
}

li {
    display: block;
}

#l1 {
    padding-left: 0pt;
}

/* #l1> li>*:first-child:before {
    content: " ";
    color: #374151;
    font-family: Symbol, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
} */