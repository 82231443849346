.contactus {
    padding: 0 6%;
    font-family: "Poppins";
}

.contactform {
    padding: 20px
}

.input-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.input-group {
    flex-basis: 48%;
}

.input-group2 {
    flex-basis: 48%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

/* option:not(:first-of-type) {
   color:blackl
  }
  /* the modification */
/* option:first-of-type {
    color:green;
  } */
/* select option { color: black; }
select option:first-child{
  color: green !important;
}
select:invalid,
select option[value=""] {
  color: #999999;
} */


/* input[type='date']::before
{
content:attr(placeholder);
} */
/* input[type='date']::after
{
content:attr();
}
input[type='date']:focus
{
content:'';
} */
input,
select {
    width: 100%;
    border: 0px;
    border-bottom: 2px solid #ccc;
    border-bottom-color: #B9C5D2;
    outline: none;
    padding-bottom: 5px;
    background-color: #fff;
}

.contactinputemail:visited {
    background: transparent !important;
}

input:focus,
select:focus {
    width: 100%;
    border: none;
    border-bottom: 2px solid #4189DD;
    border-bottom-color: #4189DD;
    outline: none;
    padding-bottom: 5px;
}

.disablebtn {
    opacity: 0.5;
}

.enablebtn {
    opacity: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
    transition: background-color 5000s;
    background-color: green !important;
    /* -webkit-backg-fill-color: #fff!important; */
}

select:-webkit-autofill,
select:-webkit-autofill:active,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover {
    transition: background-color 5000s;
    background-color: #fff !important;
    color: #000;
    /* -webkit-backg-fill-color: #fff!important; */
}

input[type='date']:-webkit-autofill,
input[type='date']:-webkit-autofill:active,
input[type='date']:-webkit-autofill:focus,
input[type='date']:-webkit-autofill:hover {
    transition: background-color 5000s;
    background-color: #fff !important;
    /* -webkit-backg-fill-color: #fff!important; */
}

input[type='date'] {
    display: block;
}

input[type="date"] {
    /* Remove default iOS styles */
    -webkit-appearance: none;
    appearance: none;
    /* Add custom styles */
    background-color: #fff;
    color: #C1C4D2;
}

input[type="date"]::-webkit-clear-button {
    /* Remove the clear button */
    display: none;
}

input[type="date"]::-webkit-inner-spin-button {
    /* Remove the spinner */
    display: none;
}

input::-webkit-input-placeholder {
    color: #C1C4D2;
    font-weight: 500;
    font-family: "Poppins";
}

.contactselect {
    /* color:#C1C4D2 !important; */
    color: #C1C4D2;
    opacity: 0.5;
    font-family: "Poppins";
}

option {
    color: black;
}

.contactselected {
    appearance: none;
}

#myDateInput::placeholder {
    color: red;
}

.contactuslabel {
    color: #061A32;
    font-weight: 400;
    font-size: 17px;
}

.contactusblue {
    color: #4189DD;
    font-weight: 400;
    font-size: 17px;
}

.contactusred {
    color: red;
    font-weight: 400;
    font-size: 17px;
}

.contactusnotlabel {
    color: #A1A4B1;
    font-weight: 400;
    font-size: 17px;
}

.contactinput {
    height: 33px;
    font-size: 17px;
}

::placeholder {
    font-size: 17px;
    color: #C1C4D2;
}

input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: #fff !important;
    color: fieldtext !important;
}

.dateplaceholder {
    color: #C1C4D2;
    opacity: 0.5;
}

.dateselectplaceholder {
    color: black;
}

.selectMenu {
    left: 52%;
    width: 30vh;
    height: 30vh;
    box-shadow: 1px 1px solid white;
}

.cardmenu {
    width: 40vw;
    height: auto;
    z-index: 222;
    top: 32%;
    /* top: -15%; */
}

.dropdown-item1 {
    font-size: 14px;
    padding: 10px;
    color: gray;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

}

.dropdown-item1:hover {
    color: black;
}

.dropdown-itemlast {
    font-size: 14px;
    padding: 10px;
    color: gray;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;

}

.dropdown-itemlast:hover {
    color: black;
}

@media (max-width:768px) {
    .input-row {
        display: flex;
        flex-direction: column;

        margin-bottom: 20px;
    }

    .input-group {
        margin-bottom: 20px;
    }

}

.input-group input:-internal-autofill-selected {
    background-color: transparent;
}

@media (max-width:500px) {

    /* .crosslastname{
        left:84% !important;
    } */
}

@media screen and (min-width:950px) and (max-width:952px) and (min-height:1520px) and (max-height:1522px) {
    .contactus {
        padding: 10% 7% !important;
    }

    .input-row {
        margin-bottom: 9%;
    }

    .headingterms {
        font-size: 60px;
    }
}

@media screen and (min-width:800px) and (max-width:980px)and (min-height:1300px) and (max-height:1522px) {

    .contactus {
        height: 65vh;
        overflow: scroll;
    }
}