
.policyname{
    font-size:23px;
    /* font-weight: 400; */
    color:#061A32;
  
    font-family:"Poppins"

}
.policynamecancel{
    font-size:23px;
    /* font-weight: 400; */
    color:#061A32;
   
    font-family:"Poppins"

}
.legalheaderf{
    margin-left:4px;
    font-weight:400;
}
.legalheader{
    margin-left:7px;
    font-weight:500 !important;
}
.legalheaderpol
{
    margin-left:0px;
    font-weight:500 !important;
}
.crossIcon{
    margin-top: 15px;;
}
@media (max-width:512px) {
    .crossIcon{
        margin-top: 2px;
        left:-5px;
    }
    .policynamecancel{
        margin-left: 0px;
        font-size:20px;
    }
    .policyname{
        font-size:20px;
    }
}
@media screen and (min-width:800px) and (max-width:980px)and (min-height:1300px) and (max-height:1522px)
{

    .commonpara
    {
       margin-bottom: 25px;
    }
}