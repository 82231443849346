* {
    margin: 0;
    padding: 0;
    text-indent: 0;
}

h1 {
    color: black;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 24pt;
}

p {
    color: #374151;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 12pt;
    margin: 0pt;
}

li {
    display: block;
}

#cgl1 {
    padding-left: 0pt;
    counter-reset: c1 1;
}

#cgl1> li>*:first-child:before {
    counter-increment: c1;
    content: counter(c1, decimal)".";
    color:  #4189DD;
    font-Weight:600 !important;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
}

#cgl1> li:first-child>*:first-child:before {
    counter-increment: c1 0;
}
@media(max-width:500px)
{
.extraspace{
    font-size:8px !important;
    line-height:1.5rem !important;
}
.extraspace1{
    font-size:8px !important;
    line-height:1.5rem !important;
    padding-left:29pt !important;
}
.extraheading{
    font-size:12px !important;
    margin-bottom: 8px !important;
    margin-top: 8px !important;
}
.extraheading1{
    font-size:12px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}
.extradot
{
    top:0px !important;
}
.extraheading2{
    font-size:10px !important;
    margin-bottom: 8px !important;
    margin-top: 8px !important;
}

}