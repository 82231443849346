.becomelocalbtn{
    width:160px !important;
    color:#4189DD;
    background-color: #C6DCF5;
    padding:14px;
    height:50px;
    border-radius: 15px;
    font-size:16px;
    font-weight: 600;
    cursor:pointer;
    font-family: "Poppins";
    text-align: center;
    /* margin-top: 20px; */
   
}
@media(max-width:999px){
.becomelocalbtn{
    width:126px !important;
    font-size:14px;
}
}
@media(max-width:550px)
{
    .becomelocalbtn{
        width:96px !important;
        font-size:9px;
        padding:10px;
        border-radius: 7px;
        height:32px;
    }
   
}