.sliderContainer{
  padding: 0 7%;
}
.containerSlider {
  height: 160%;
  margin-left: 2.5px;
  position: absolute;
}
.ellipse {
  background-color: rgba(65, 137, 221, 0.3);
  /* height: 298px;
  width: 298px; */
  height: 130px;
  width:130px;
  object-fit: contain;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  margin-top: 30px;
}
@media(min-width:481px){
  .containerSlider{
    height:195%;
  }
}
@media(min-width:768px){
  .containerSlider{
    /* height:322%; */
    height:232%;
  }
  .sliderContainer{
    margin-top:60px;
  }
}
@media(min-width:1024px){
  .sliderContainer{
    height: 581px;
  }
  .ellipse{
    width: 298px;
    height: 298px;
  }
  .containerSlider {
    height: 138%;
    margin-left: 0px;
}
}
@media(min-width:750px) and (max-width:1000px)
{
  .ellipse{
    width: 277px !important;
    height: 178px !important;
  }
}
/* @media(max-width:424px)
{
  .containerSlider {
    height: 116%;
  }
} */
@media(max-width:458px)
{
  .containerSlider {
    height: 109%;
  }
}
@media(max-width:424px)
{
  .containerSlider {
    height: 113%;
  }

}
/* .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 581px;
}
.sliderDiv {
  width: 70%;
  margin: 0 7%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sliderDivLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
  flex-wrap: wrap;
  position: relative;
}
.sliderDivRight {
  flex-basis: 50%;
  display: flex;
}

.ellipse {
  background-color: rgba(65, 137, 221, 0.3);
  height: 298px;
  width: 298px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.containerSlider {
  position: absolute;
  margin-left: 6px;
} */

/* @media ((min-width: 320px) and (max-width: 480px)) {
  .container {
    height: 550px;
  }
  .sliderDiv {
    flex-direction: column;
    justify-content: center;
    gap: 40px;
  }
  .ellipse {
    width: 130px;
    height: 130px;
    object-fit: contain;
  }
  .slider {
    width: 150%;
  }
  .sliderDivLeft {
    flex-grow: 1;
  }
  .sliderDivRight {
    justify-content: center;
    margin-top: 40px;
  }
  .containerSlider {
    height: 157%;
    margin-left: 2.5px;
  }
} */
/* @media ((min-width: 481px) and (max-width: 767px)) {
  .container {
    height: 500px;
  }
  .sliderDiv {
    flex-direction: column;
    justify-content: center;
  }
  .ellipse {
    width: 150px;
    height: 150px;
  }
  .slider {
    width: 150%;
  }
  .sliderDivRight {
    justify-content: center;
    margin-top: 20px;
  }
  .containerSlider {
    height: 171%;
    margin-left: 2.5px;
  }
}
@media ((min-width: 768px) and (max-width: 1024px)) {
  .sliderDiv {
    width: 100%;
  }
  .container{
    margin-bottom: 80px;;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */
