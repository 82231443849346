.titleContainer{
  background-color: #4189dd;
  background-image: url("../../assets/title/worldMap.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.inputDiv{
  display: flex;
  justify-content: center;
}
.titleWareeg{
  padding:10% 7%;
}
p{
  text-align: center;
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
}
.para1 {
  font-weight: 700 !important;
  font-size: 40px ;
  line-height: 60px !important; 
  color:#fff;
  font-style: "Poppins";
}
.para2 {
  font-size: 15px;
  /* line-height: 50px; */
  font-weight: 400;
  color:#fff;
}
.image{
  display:none;
}
.phoneImage {
  max-width: 75%;
  height: auto;
}
.allappbtns{
  margin-top: 20px;
}
.subscriptionDetails {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  color: #ffffff;
  visibility: hidden;
  text-align: center;
  font-size: 10px;
}
.subscriptionDetailsVisible {
  visibility: visible;
}
.para2 {
  font-size: 18px;
  /* line-height: 80px; */
}
.headingfirstpara{
  top:84px !important;
}
@media (min-width:768px) {
  .titleWareeg{
    padding:10% 7%;
  }
  p{
    text-align: left;
  }
  .para1{
    font-size: 30px;
  }
  /* .para2 {
    font-size: 18px;
    line-height: 80px;
  } */
  .inputDiv{
    justify-content: flex-start;
  }
  .subscriptionDetails{
    text-align: left;
  }
  .phoneImage{
    height:95%;
  }
}
@media (max-width:768px) {
  .para1{
    font-size: 20px;
  }
}
@media (min-width:1023px) {
  .titleWareeg{
    padding:2% 5% 7% 7%;
  }
  .para1 {
    font-size: 25px;
    line-height: 60px;
  }
  .para2 {
    font-size: 22px;
    line-height: 28.75px; 
    margin-top: 10px;
  }
  .subscriptionDetails {
    line-height: 30px;   
    font-size: 14px;  
  }
  .phoneImage{
    height: 89%;
  } 
}
@media (min-width:1200px){
  .para1{
    font-size: 33px;
    line-height: 60px;
  }
  /* .para2{
    line-height: 200px;
  } */
}

@media (min-width:1500px){
  .para1{
    font-size: 45px;
    line-height: 60px;
    /* margin-top: 3%; */
  }
  /* .para2{
    line-height: 200px;
  } */
  .phoneImage{
    height: 100%;
    width:100%;
   
  }
}
@media (max-width: 952px)
{
  .headingfirstpara{
    top:10px !important;
  }
.para1 {
   
     line-height: 40px !important; 
}
.para2 {
  font-size: 12px;
  /* line-height: 80px; */
}
.appbtns img{
  width:100px;
}
}
@media (max-width: 500px)
{
.para1 {
   
     line-height: 30px !important; 
}
.para2 {
   
  line-height: 40px !important; 
}
}
@media (max-width: 500px)
{
  .para2 {
   
    line-height: 20px !important; 
  }
}