
  * {
                margin: 0;
                padding: 0;
                text-indent: 0;
            }

            h1 {
                color: black;
                font-family: "Poppins";
                /* font-family: Calibri, sans-serif; */
                font-style: normal;
                font-weight: bold;
                text-decoration: none;
                font-size: 24pt;
            }

            .p, p {
                color: rgb(107, 107, 107);
                font-family: "Poppins", sans-serif;
                font-style: normal;
                font-weight: normal;
                text-decoration: none;
                font-size: 12pt;
                margin: 0pt;
            }

            h2 {
                color: #374151;
                font-family: "Poppins, sans-serif";
                font-style: normal;
                font-weight: bold;
                text-decoration: none;
                font-size: 12pt;
            }

            li {
                display: block;
            }

            #l1 {
                padding-left: 0pt;
            }

            /* #l1> li>*:first-child:before {
                /* content: "🔴";
                color: #374151;
                font-family: Symbol, serif;
                font-style: normal;
                font-weight: normal;
                text-decoration: none;
                font-size: 12pt; */
                /* width:20px;
                height:20px;
                text-align: center;
                display: inline-block;
                border-radius: 50%;
                background-color:#6B6B6B ;
            } */ 

            #cl2 {
                padding-left: 0pt;
            }

            /* #cl2> li>*:first-child:before {
                /* content: "🔴";
                color: #6B6B6B;
                font-family: Symbol, serif;
                font-style: normal;
                font-weight: normal;
                text-decoration: none;
                font-size: 10pt; */
                /* width:20px;
                height:20px;
                text-align: center;
                display: inline-block;
                border-radius: 50%;
                background-color:#6B6B6B ; */
           /* // } */ 
          #listspan{
                width:8px;
                height:8px;
                text-align: center;
                display: inline-block;
                border-radius: 50%;
                background-color:#6b6b6b; 
                margin-right: 5px;
                opacity: 0.8;
            }
            /* style={{ width:"8px",
                height:"8px",
                
                display:"inline-block",
                borderRadius: "50%",
                backgroundColor:"#6b6b6b" ,
                marginRight: "5px",
                opacity:"0.8"}} */

            #cl3 {
                padding-left: 0pt;
            }

            /* #cl3> li>*:first-child:before {
                content: "🔴";
                color: #6B6B6B;
                font-family: Symbol, monospace;
                font-style: normal;
                font-weight: normal;
                text-decoration: none;
                font-size: 10pt;
            } */

            #cl4 {
                padding-left: 0pt;
            }

            /* #cl4> li>*:first-child:before {
                /* content: "";
                color: #6B6B6B;
                font-family: Symbol, serif;
                font-style: normal;
                font-weight: normal;
                text-decoration: none;
                font-size: 10pt; */
              
            /* } */
             

            li {
                display: block;
            }

            #l5 {
                padding-left: 0pt;
            }

            /* #l5> li>*:first-child:before {
                content: "o ";
                color: #374151;
                font-family: "Courier New", monospace;
                font-style: normal;
                font-weight: normal;
                text-decoration: none;
                font-size: 12pt;
            } */